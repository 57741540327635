<template>
    <div class="c-work-rail" >
        <div class="o-container || o-padding -padding-wide-top">
            <ul class="c-work-rail_list" ref="wrapper">
                <li
                    v-for="(word, wordIndex) in textArray"
                    :key="wordIndex"
                    class="c-work-rail_item || c-heading-work -rail"
                    ref="item"
                >
                    {{ word }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
import Raf from "quark-raf";
import { mapState } from "vuex";
export default {
    name: "WorkRail",
    props: {
        message: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            textArray: [],
        };
    },
    computed: {
        ...mapState("main", ["isFontsLoaded"]),
        ...mapState("metrics", ["width"]),
    },
    watch: {
        isFontsLoaded: "onFontsLoaded",
        width: "onResize",
    },
    created() {
        // Controls
        this.speed = 2;

        // Offset values
        this.normalizedTranslatedValue = 0;
        this.translatedValue = 0;
        this.turn = 0;
        this.lastTurn = 0;

        // Raf values
        this.start = Date.now();
        this.isPlaying = false;

        // Array from message
        this.textArray = this.message.trim().split(" ");

        // Binding
        this.onUpdateBind = this.onUpdate.bind(this);
    },
    mounted() {
        this.$items = this.$refs.wrapper.querySelectorAll("li");

        if (this.isFontsLoaded) {
            this.$nextTick(() => {
                this.resetTransform();
                this.computeMetrics();
            })
        }

        this.fakeTimeout = setTimeout(() => {
            this.playRail();
        }, 2000)
    },
    beforeUnmount() {
        clearTimeout(this.fakeTimeout)
        this.stopRail();
    },
    methods: {
        // Events
        onFontsLoaded() {
            this.resetTransform();
            this.computeMetrics();
        },

        onResize() {
            this.resetTransform();
            this.computeMetrics();
        },

        // RAF
        playRail() {
            if (this.isPlaying) return;
            this.isPlaying = true;
            Raf.add(this.onUpdateBind);
        },

        stopRail() {
            if (!this.isPlaying) return;
            this.isPlaying = false;
            Raf.remove(this.onUpdateBind);
        },

        // Initialize
        initSlider() {
            this.computeMetrics();
        },

        // Methods
        computeMetrics() {
            this.metrics = {};
            this.metrics.elWidth = this.$el.offsetWidth;
            this.metrics.wrapperWidth = this.$refs.wrapper.offsetWidth;
            this.metrics.itemsMetrics = Array.from(this.$items).map((item) => {
                const { width, left } = item.getBoundingClientRect();
                return {
                    width,
                    left: left - this.normalizedTranslatedValue,
                };
            });
        },

        setPositions(translatedValue) {
            this.turn = Math.floor(
                Math.abs(this.translatedValue / this.metrics.wrapperWidth)
            );
            this.normalizedTranslatedValue = translatedValue % this.metrics.wrapperWidth;

            // Each turn => reset
            if (this.turn != this.lastTurn) {
                this.lastTurn = this.turn;
                this.resetTransform();
            }

            // Loop
            let index = 0;
            while (index < this.metrics.itemsMetrics.length) {
                const itemMetrics = this.metrics.itemsMetrics[index];
                const itemWidth = itemMetrics.width;
                const itemLeft = itemMetrics.left;

                const distanceToHide = itemLeft + itemWidth;
                if (this.normalizedTranslatedValue < distanceToHide * -1) {
                    const $item = this.$items[index];
                    this.translateItem($item);
                }

                index++;
            }

            // Translate slider
            if (this.$refs.wrapper) {
                this.$refs.wrapper.style.transform = `translate3d(${this.normalizedTranslatedValue}px, 0, 0)`;
            }
        },

        translateItem($el) {
            const translateValue = this.metrics.wrapperWidth;
            $el.style.transform = `translate3d(${translateValue}px, 0, 0)`;
        },

        resetTransform() {
            let index = 0;
            while (index < this.$items.length) {
                const $item = this.$items[index];
                $item.style.transform = "";
                index++;
            }

            this.speed = 2 * Math.max(.4, Math.min(window.innerWidth / 1440, 1));
        },

        // RENDER
        onUpdate() {
            this.translatedValue -= 1 * this.speed;
            this.setPositions(this.translatedValue);
        },
    },
};
</script>