<template>
    <div class="c-work-intro" >
        <div class="o-container || o-padding -padding-medium-top">
            <div class="o-grid -col-12 -gutter">
                <div class=" u-gc-1/13 u-gc-6/13@from-small u-gc-7/13@from-medium u-gc-8/13@from-large">
                    <text-reveal v-if="introduction">
                        <p class="c-work-intro_title || c-text-work -intro-text || u-anim-text" v-html="introduction" data-scroll data-scroll-offset="30%" />
                    </text-reveal>
                    <div v-if="items.length" class="o-padding -padding-medium-top">
                        <list :items="items" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListVue from "../blocks/List.vue";
import TextRevealVue from '../blocks/TextReveal.vue';
export default {
    name: "WorkIntro",
    components: {
        list: ListVue,
        "text-reveal": TextRevealVue,
    },
    props: {
        introduction: {
            type: String,
            default: ''
        },
        items: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {};
    },
    mounted() {},
    methods: {},
};
</script>