<template>
    <div class="c-work-composition" :class="['-composition-' + compositionIndex]" >
        <div class="o-container || o-padding -padding-wide-top">
            <div class="c-work-composition_inner">
                <div class="c-work-composition_description || u-gc-1/7@from-small">
                <text-reveal v-if="description">
                    <p class="c-work-composition_description_content || c-text-work -composition || u-anim-text" v-html="description" data-scroll data-scroll-offset="30%" />
                </text-reveal>
                </div>
                <div class="c-work-composition_image -odd || u-gc-7/13@from-small">
                    <div v-if="imageSrcOdd" class="c-figure">
                        <div class="c-figure_inner || o-lazy">
                            <img 
                                class="c-figure_image || o-lazy_image" 
                                :src="defaultSrcOdd"
                                :data-src="imageSrcOdd" 
                                alt="tmp"
                                data-scroll
                                data-scroll-call="lazyLoad"
                            />
                        </div>
                    </div>
                </div>
                <div class="c-work-composition_image -even || u-gc-1/7@from-small">
                    <div v-if="imageSrcEven" class="c-figure">
                        <div class="c-figure_inner || o-lazy">
                            <img 
                                class="c-figure_image || o-lazy_image" 
                                :src="defaultSrcEven"
                                :data-src="imageSrcEven"
                                alt="tmp"
                                data-scroll
                                data-scroll-call="lazyLoad"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getContextImageUrl } from "../../core/images/contextImage";
import TextRevealVue from '../blocks/TextReveal.vue';

export default {
    name: "WorkColumn",
    components: {
        "text-reveal": TextRevealVue
    },
    props: {
        compositionIndex: {
            type: Number,
            default: 0
        },
        description: {
            type: String,
            default: ''
        },
        images: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        defaultSrcOdd() {
            let src;
            switch (this.compositionIndex) {
                case 0:
                    src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 2'%3E%3C/svg%3E`;
                    break;
                case 1:
                    src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 2'%3E%3C/svg%3E`;
                    break;
                case 2:
                    src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 2'%3E%3C/svg%3E`;
                    break;
            }
            return src;
        },
        defaultSrcEven() {
            let src;
            switch (this.compositionIndex) {
                case 0:
                    src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 2'%3E%3C/svg%3E`;
                    break;
                case 1:
                    src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 3 2'%3E%3C/svg%3E`;
                    break;
                case 2:
                    src = `data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 5 8'%3E%3C/svg%3E`;
                    break;
            }
            return src;
        },
    },
    data() {
        return {
            imageSrcOdd: '',
            imageSrcEven: '',
        };
    },
    created() {
        const srcs = []

        this.images.forEach((image, index) => {
            if (this.compositionIndex === 2) {
                if (index === 1) {
                    srcs.push(getContextImageUrl(image, 'compositionAlt'))
                } else {
                    srcs.push(getContextImageUrl(image, 'composition'))
                }
            } else {
                srcs.push(getContextImageUrl(image, 'composition'))
            }
        })

        if (srcs.length) {
            this.imageSrcOdd = srcs[0]
        }

        if (srcs.length > 0) {
            this.imageSrcEven = srcs[1]
        }
    }
};
</script>