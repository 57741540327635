<template>
    <header class="c-work-hero" >
        <div class="o-container">
            <div class="o-grid -col-12 -gutter">
                <div class="u-gc-1/13 u-gc-2/12@from-small">

                    <p class="c-work-hero_index">
                        <span class="c-text-work -index-italic"><i>F</i></span>
                        <span class="c-text-work -index">-{{ uPad(index, 10) }}</span>
                    </p>

                    <div class="o-padding -padding-tiny-top">
                        <text-reveal>
                        <h1
                            class="c-work-hero_title || c-heading-work -title"
                            v-html="title"
                        />
                        </text-reveal>
                    </div>

                    <div class="c-work-hero_cover" ref="cover">
                        <div v-if="imageSrc" class="c-figure">
                            <div class="c-figure_inner || o-lazy">
                                <img 
                                    class="c-figure_image || o-lazy_image" :class="{'-lazy-loaded': imageLoaded}"
                                    :src="imageSrc"
                                    alt="tmp"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { getContextImageUrl } from "../../core/images/contextImage";
import TextRevealVue from '../blocks/TextReveal.vue';

export default {
    name: "WorkHero",
    components: {
        "text-reveal": TextRevealVue,
    },
    props: {
        title: {
            type: String,
            required: true,
        },
        index: {
            type: Number,
            default: 0,
        },
        cover: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            imageSrc: '',
            imageLoaded: false
        };
    },
    computed: {
        ...mapState("metrics", ["width"]),
    },
    watch: {
        width: "onResize",
    },
    created() {
        this.imageSrc = getContextImageUrl(this.cover, 'cover')
    },
    mounted() {
        this.$nextTick(() => {
            this.forceScrollUpdate()
        })

        setTimeout(() => {
            this.imageLoaded = true
        }, 100)

        console.log(this.computeMaxWidth())
    },
    methods: {
        ...mapActions('main', ['forceScrollUpdate']),

        uPad(number, base) {
            return number < base ? "0" + number.toString() : number.toString();
        },

        onResize() {
            this.computeMaxWidth();
        },

        computeMaxWidth() {
            const maxHeight = window.innerHeight - 200;
            const maxWidth = Math.round((16 / 9) * maxHeight);

            const translateY = -this.$refs.cover.offsetTop + window.innerHeight / 2 - this.$refs.cover.offsetHeight / 2
            const scale = maxWidth / this.$refs.cover.offsetWidth

            this.$el.style.setProperty("--translateY", `${translateY}px`);
            this.$el.style.setProperty("--scale", `${scale}`);
        },
    },
};
</script>