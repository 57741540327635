<template>
    <div class="c-work-column" >
        <div class="o-container || o-padding -padding-wide-top">
            <div class="c-work-column_inner" :id="'Container' + uid">
                <div class="c-work-column_description" :class="{'u-gc-1/7@from-small': isReversed, 'u-gc-7/13@from-small': !isReversed}">
                    <text-reveal v-if="description">
                        <p class="c-work-column_description_content || c-text-work -composition || u-anim-text" v-html="description" data-scroll data-scroll-offset="30%"/>
                    </text-reveal>
                </div>
                <div class="c-work-column_image-sticky" :class="{'u-gc-7/13@from-small': isReversed, 'u-gc-1/7@from-small': !isReversed}" data-scroll data-scroll-sticky :data-scroll-target="'#Container' + uid">
                    <div v-if="stickySrc" class="c-work-column_image">
                        <div class="c-figure">
                            <div class="c-figure_inner || o-lazy">
                                <img 
                                    class="c-figure_image || o-lazy_image" 
                                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 3'%3E%3C/svg%3E"
                                    :data-src="stickySrc" 
                                    alt="tmp"
                                    data-scroll
                                    data-scroll-call="lazyLoad"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div class="c-work-column_image-list" :class="{'u-gc-1/7@from-small': isReversed, 'u-gc-7/13@from-small': !isReversed}">
                    <div class="c-work-column_image" v-for="(src, srcIndex) in imagesSrcs" :key="srcIndex">
                        <div class="c-figure">
                            <div class="c-figure_inner || o-lazy">
                                <img 
                                    class="c-figure_image || o-lazy_image" 
                                    src="data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 2 3'%3E%3C/svg%3E"
                                    :data-src="src" 
                                    alt="tmp"
                                    data-scroll
                                    data-scroll-call="lazyLoad"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { uuidv4 } from "../../utils/uuid"
import { getContextImageUrl } from "../../core/images/contextImage";
import TextRevealVue from '../blocks/TextReveal.vue';

export default {
    name: "WorkColumn",
    components: {
        "text-reveal": TextRevealVue,
    },
    props: {
        isReversed: {
            type: Boolean,
            default: false
        },
        description: {
            type: String,
            default: ''
        },
        images: {
            type: Array,
            default: () => []
        }
    },
    data() {
        return {
            uid: uuidv4(),
            stickySrc: '',
            imagesSrcs: []
        };
    },
    created() {
        const srcs = []

        this.images.forEach((image) => {
            srcs.push(getContextImageUrl(image, 'column'))
        })

        if (srcs.length) {
            this.stickySrc = srcs.shift()
        }

        this.imagesSrcs = srcs
    },
    mounted() {},
    methods: {},
};
</script>