<template>
    <div class="c-work-text" >
        <div class="o-container || o-padding -padding-wide-top">
            <text-reveal v-if="text">
                <p class="c-work-text_content || c-text-work -text || u-anim-text" v-html="text" data-scroll data-scroll-offset="30%" />
            </text-reveal>
        </div>
    </div>
</template>

<script>
import TextRevealVue from '../blocks/TextReveal.vue';
export default {
    name: "WorkText",
    components: {
        "text-reveal": TextRevealVue,
    },
    props: {
        text: {
            type: String,
            required: true
        }
    }
};
</script>