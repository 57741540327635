<template>
    <div class="c-work-credits">
        <div class="o-container || o-padding -padding-big-top -padding-wide-bottom" >
            <div class="o-grid -col-12 -gutter">
                <div class="u-gc-1/13 u-gc-6/13@from-small u-gc-7/13@from-medium u-gc-8/13@from-large" >
                    <template v-for="(item, itemIndex) in credentials" :key="itemIndex"> 
                        <list v-if="credentials.length" :title="item.title" :items="item.list"/>
                    </template>
                    <div v-if="legals" class="o-padding -padding-large-top">
                        <div class="c-note || u-anim-fade" data-scroll>
                            <p class="c-note_content || c-text-work -note || u-color-grey-dark">{{ legals }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ListVue from "../blocks/List.vue";
export default {
    name: "WorkCredits",
    components: {
        list: ListVue,
    },
    props: {
        credentials: {
            type: Array,
            default: () => []
        },
        legals: {
            type: String,
            required: false
        }
    }
};
</script>